import Web3 from "web3";
import {Moralis} from "moralis"

// export const web3 = new Web3(Web3.providers.HttpProvider(web3Provider));
export const web3 = new Web3(Web3.givenProvider);

// export const serverAddress = 'wss://bvbrjf.ap-northeast-2.colyseus.dev'
export const serverAddress = 'wss://server.ky3d.com'//远程服务器
// export const serverAddress = 'ws://localhost:2567'//本地服务器

const serverUrl = "https://dcxpdvcrzcqb.usemoralis.com:2053/server";
const appId = "lPGf7fPmIKr90LUVNfCAdHjNNvIwdkeACU5xLUDO";
Moralis.start({serverUrl, appId}).then();

export const MoralisInstance = Moralis
